module.exports = [{
      plugin: require('/opt/build/repo/client/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/client/node_modules/gatsby-wordpress-inline-images/gatsby-browser.js'),
      options: {"plugins":[],"baseUrl":"hackneyneu.michaelwatts.co","protocol":"https"},
    },{
      plugin: require('/opt/build/repo/client/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
